import {Navigate} from "react-router-dom";

const filterRoutes = (routes) => {
    let isLogin = false;
    const accessToken = localStorage.getItem('accessToken');
    const time = localStorage.getItem('time') ? localStorage.getItem('time') : 0;
    if (accessToken && time*1000 > new Date().getTime()) {
        isLogin = true;
    }else {
        localStorage.removeItem('accessToken');
    }
    if (!isLogin) {
        return routes.map(route => {
            const {auth} = route;
            if (auth) {
                return {...route, element: <Navigate key={route.path} to="/Login"/>}
            }
            if (route.children) {
                route.children = filterRoutes(route.children);
            }
            return route;
        });
    }
    let guarded = localStorage.getItem("g");
    if (guarded !== '[*]') {
        guarded = JSON.parse(localStorage.getItem("g"));
    }
    if (guarded === null) {
        localStorage.removeItem('accessToken');
    }
    const DATA = routes.map(route => {
        const {auth, name} = route;
        if (auth && !guarded.includes(name) && guarded !== "[*]") {
            return {...route, element: <Navigate key={route.path} to="/401"/>}
        }
        if (route.children) {
            route.children = filterRoutes(route.children);
        }
        return route;
    });
    return DATA;
};

export default filterRoutes;
