import React from "react";
import {Loading} from "react-vant";
import "./index.css";


export default function loading() {
    return (
        <>
            <div className="text-center">
                <Loading type="ball" vertical>加载中...</Loading>
            </div>
        </>
    )
}