import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import RouteGuarded from "./routes/RouteGuarded";


export default function App() {
    const element = useRoutes(RouteGuarded(routes));
    return (
        <>
            {element}
        </>
    )
}
