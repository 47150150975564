import {lazy, Suspense} from 'react';
import {Navigate} from "react-router-dom";
import Loading from "../components/Loading";

const Login = lazy(() => import('../page/Login'));
const Home = lazy(() => import('../page/Home'));
const Company = lazy(() => import('../page/Company'));
const CompanyOrderRecord = lazy(() => import('../page/CompanyOrderRecord'));
const CompanyMerchant = lazy(() => import('../page/CompanyMerchant'));
const CompanyMerchantRecord = lazy(() => import('../page/CompanyMerchantRecord'));
const CompanyMerchantElectricity = lazy(() => import('../page/CompanyMerchantElectricity'));
const CompanyMerchantRecordFlow = lazy(() => import('../page/CompanyMerchantRecordFlow'));
const CompanyOrderRecordFlow = lazy(() => import('../page/CompanyOrderRecordFlow'));
const Merchant = lazy(() => import('../page/Merchant'));
const MerchantOrderRecord = lazy(() => import('../page/MerchantOrderRecord'));
const Devices = lazy(() => import('../page/Devices'));
const ElectricityConsumptionRecord = lazy(() => import('../page/ElectricityConsumptionRecord'));

const Fallback = () => <><Loading/></>;


const routes = [
    {
        path: "/",
        name: "Login",
        auth: false,
        element: <Suspense fallback={<Fallback/>}><Login/></Suspense>,
    },
    {
        path: "/Login",
        name: "Login",
        auth: false,
        element: <Suspense fallback={<Fallback/>}><Login/></Suspense>,
    },
    {
        path: "/home",
        name: "app主页",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><Home/></Suspense>,
        children: [
        ]
    },
    {
        path: "/company",
        name: "公司列表",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><Company/></Suspense>,
    },
    {
        path: "/companymerchant",
        name: "公司商户列表",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><CompanyMerchant/></Suspense>,
    },
    {
        path: "/companymerchantelectricity",
        name: "公司商户电表列表",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><CompanyMerchantElectricity/></Suspense>,
    },
    {
        path: "/companymerchantrecord",
        name: "公司商户列表充值记录",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><CompanyMerchantRecord/></Suspense>,
    },
    // {
    //     path: "/companymerchantrecordflow",
    //     name: "公司商户列表充值记录",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><CompanyMerchantRecordFlow/></Suspense>,
    // },
    {
        path: "/CompanyOrderRecord",
        name: "公司充值订单",
        auth: true,
        element: <Suspense fallback={<Fallback/>}><CompanyOrderRecord/></Suspense>,
    },
    // {
    //     path: "/companyorderRecordflow",
    //     name: "公司充值订单划账信息",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><CompanyOrderRecordFlow/></Suspense>,
    // },
    // {
    //     path: "/merchant",
    //     name: "商户列表",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><Merchant/></Suspense>,
    // },
    // {
    //     path: "/merchantorderrecord",
    //     name: "商户扣费记录",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><MerchantOrderRecord/></Suspense>,
    // },
    // {
    //     path: "/devices",
    //     name: "电表列表",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><Devices/></Suspense>,
    // },
    // {
    //     path: "/electricityconsumptionrecord",
    //     name: "电表记录",
    //     auth: true,
    //     element: <Suspense fallback={<Fallback/>}><ElectricityConsumptionRecord/></Suspense>,
    // },
    {
        path: "/401",
        element: <div>无权限...</div>
    },
    {
        path: "/404",
        element: <div>404...</div>
    },
    {
        path: "*",
        element: <Navigate to="/404"></Navigate>
    }
]


export default routes;